<template>
  <section
    ref="page"
    class="page-container">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <h2
        v-if="showTitle"
        class="title-page">
        Domestic helper Insurance
      </h2>
      <h2
        v-if="isRewTitle"
        class="title-page">
        Renew Policy
      </h2>
      <h2
        v-if="isEdit&&is_save"
        class="title-page">
        Edit Policy
      </h2>
      <section class="margin-right">
        <policy-detail
          ref="policy-detail"
          :form="formData"
          :formDataLock="formDataLock"
          @policyData="getChangeFormData"
          @getFormData="getSubFormData"></policy-detail>
        <div class="info-box">
          <v-title title="Policyholder Details"></v-title>
          <policy-holder
            ref="policy-holder"
            :form="formData"
            :formDataLock="formDataLock"
            :client-type="clientType"
            @getFormData="getSubFormData"
            @address="handlerAddress"
          />
        </div>
        <div class="info-box">
          <v-title title="Insured Detail"></v-title>
          <inusred-detail-form
            ref="insured-detail"
            :isShowSelect="false"
            :form="formData"
            :formDataLock="formDataLock"
            :isTitle="true"
            :client-type="clientType"
            @getFormData="getSubFormData"
            @sameAddress="sameAddress"></inusred-detail-form>
        </div>
        <div class="info-box">
          <v-title title="Domestic Helper's Detail"></v-title>
          <domestic-helper-form
            ref="domestic-helper"
            :formDataLock="formDataLock"
            :form="formData.domestic_helper_detail"
            @getFormData="getSubFormData"
          ></domestic-helper-form>
        </div>

        <div class="info-box">
          <v-title title="Remarks"></v-title>
          <el-form-item prop="remark">
            <v-input
              v-model="formData.remark"
              type="textarea"
              :rows="4"></v-input>
          </el-form-item>
        </div>
        <div class="info-box">
          <v-title title="Documents"></v-title>
          <document
            ref="document"
            :form="formData"
            :formDataLock="formDataLock"
            :classType="formData.class_id"
            :clientType="formData.client_type"
            @getFormData="getSubFormData"></document>
        </div>
        <div class="info-box">
          <referral
            ref="referral"
            :formDataLock="formDataLock"
            :form="formData.referral"
            @getFormData="getSubFormData"></referral>
        </div>
      </section>

    </el-form>
  </section>
</template>

<script>
import policyDetail from '@/views/components/policyDetail/policyDetail'
import PolicyHolder from '@/views/components/policyDetail/policyHolderForm'
import document from '@/views/components/motor/document'
import referral from '@/views/components/motor/referral'
import domesticHelperForm from '@/views/components/motor/domesticHelperForm'
import {saveQuotationMotor} from '@/api/quotation'
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
import { mapState, mapActions } from 'vuex'
import InusredDetailForm from '@/views/components/motor/insuredDetailForm'
export default {
  name: 'domesticForm',
  components: {
    InusredDetailForm,
    policyDetail,
    PolicyHolder,
    document,
    referral,
    domesticHelperForm
  },
  mixins: [MixinRules,MixinOptions],
  data() {
    return {
      formDataLock: 0, // 表單鎖，防止外層修改數據覆蓋了組件裏的表單數據
      ncdShow:0,
      order_no:'',
      carTypeName: 1001,
      clientType: 1,
      isNext: false,
      cover_type: 1,
      insurer:0,
      policyOneDriver:false,
      subForm: {
        'policy-detail':false,
        'policy-holder':false,
        'domestic-helper':false,
        'insured-detail':false,
        'document':false,
        'referral':false,
      },
      formData: {
        class_id:1601,
        draft:false,
        coverage: {
          cover_type: 1, //1 third party 2 comprehensive
          hire_purchase_owner: 1, //1 yes,no-2
          hire_purchase_owner_id: '',
          estimated_vehicle_value: 0,
          policy_send_to_hire_purchase_owner_date: '',
        },
        remark: '',
      }
    }
  },

  computed: {
    ...mapState('quotation', ['quoteInformationForm']),
    isEdit(){
      if(this.quoteInformationForm)
      {
        let {is_edit=''} = this.quoteInformationForm
        return is_edit?true:false
      }return  false
    },
    is_save(){
      if(this.quoteInformationForm)
      {
        let {is_save=false} = this.quoteInformationForm
        return is_save
      }return  false
    },
    isEndorsement(){
      if(this.quoteInformationForm)
      {
        let {policy_type} = this.quoteInformationForm
        return policy_type==203?true:false
      }return  false
    },
    showTitle(){
      let {policy_type=200} = this.quoteInformationForm||{}
      return (!this.isEdit||!this.is_save)&&!this.isEndorsement&&policy_type==200
    },
    isRewTitle(){
      let {policy_type=200} = this.quoteInformationForm||{}
      return (!this.isEdit||!this.is_save)&&!this.isEndorsement&&policy_type==201
    }
  },
  created() {
    if(this.quoteInformationForm){
      this.formData = this.quoteInformationForm
      let {client_type = 1, company_id = 0, coverage = {},class_id} = this.quoteInformationForm
      let {cover_type} = coverage
      this.clientType = client_type
      this.insurer = company_id
      this.cover_type = cover_type
    }
  },
  mounted() {
    this.$nextTick(()=>{
      if(this.$route.query.isDoucment=='true')
        this.$refs.page.scrollIntoView({behavior: 'smooth', block: 'end',})
    })
  },
  methods: {
    ...mapActions('quotation', ['setQuoteInformationForm','setNcd']),
    sameAddress(data){
      if(data){
        this.$refs['policy-holder'].sameAddress()
      }
    },
    handlerAddress(data){
      if(this.$refs['insured-detail'])
        this.$refs['insured-detail'].sameAddress(data)
    },
    getChangeFormData(data) {
      this.formData = Object.assign(this.formData, data)
      let {client_type = 1, company_id = 1, coverage = {},class_id} = this.formData
      let {cover_type } = coverage
      this.carTypeName = class_id||1001
      this.clientType = client_type?client_type:this.clientType
      this.insurer = company_id
      this.cover_type = cover_type?cover_type:this.cover_type
      this.$emit('handlerCompanyId',company_id)
      this.$emit('handlerClassId',class_id)

    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    submitForm(formName) {
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key,this.is_draft)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.$refs.form.validate((valid, invalidFields) => {
        if (valid) {
          this.$emit('getFormData', formName,this.formData)

        }else{

        }
      })
    },

  }
}
</script>

<style scoped lang="scss">
@import '../../../assets/css/form.scss';
</style>
